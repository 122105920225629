/* DividendCalculator.css */

.calculator-container {
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 30px;
  max-width: 500px;
  margin: auto;
  text-align: center;
}

.input-container {
  margin-bottom: 20px;
}

.input-field {
  width: 100%;
  padding: 10px;
  border: 2px solid #007bff;
  border-radius: 8px;
  font-size: 16px;
  transition: border 0.3s ease;
}

.input-field:focus {
  border-color: #0056b3;
  outline: none;
}

.slider-container {
  margin: 20px 0;
}

.submit-button {
  background-color: #007bff;
  color: #fff;
  padding: 12px 25px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.result-container {
  margin-top: 30px;
  text-align: left;
}

.result-container h2 {
  color: #007bff;
  font-weight: bold;
}

.result-container p {
  font-size: 16px;
  margin: 10px 0;
  color: #333;
}
